<template>
  <div id="locsearch">
    <MyheadCompnent></MyheadCompnent>
    <Metabolite2LocSearchComponent></Metabolite2LocSearchComponent>
    <MyfooterCompnent></MyfooterCompnent>
  </div>
</template>

<script>
  import Metabolite2LocSearchComponent from '@/components/Browse/Metabolite2LocSearchComponent.vue'
  import MyheadCompnent from '@/components/Common/MyheadCompnent.vue'
  import MyfooterCompnent from '@/components/Common/MyfooterCompnent.vue'
  export default {
    name: 'LocSearch',
    data () {
      return {

      }
    },
    components: {
      Metabolite2LocSearchComponent,
      MyheadCompnent,
      MyfooterCompnent,
    },
  }
</script>

<style scoped lang="scss">
</style>