<template>
  <div>
    <div id="locsearchcomponent">
      <MetaboliteBrowseCompnentSearch :headT='head'></MetaboliteBrowseCompnentSearch>
      <el-row>
        <el-col :span="1">
          <div class="grid-content bg-purple"></div>
        </el-col>
        <el-col :span="22">
          <el-steps :active="step" align-center>
            <el-step title="Step 1" description="Metabolite input"></el-step>
            <el-step title="Step 2" description="Filter parameters input"></el-step>
            <el-step title="Step 3" description="Results"></el-step>
          </el-steps>
          <div class="itemselection">
            <h2>
              Introduction
            </h2>
            <div class="speciesselection" style="margin-top: 20px">
              <p style="font-size: 20px;">
                'Metabolite to Locus' provide the mGWAS results for specific compounds in various species.
                By exploring these results, users can gain insights into the differences in enzyme sequences,
                which can lead to variations in enzyme activity and subsequently result in different metabolite
                accumulation across different species. In this section, users need to first select no more than 3
                metabolites with PMhub ID or metabolite name. With filter parameters applied, PMhub will provides
                all of the mGWAS results for the features annotated with the selected metabolies. For each item,
                a genetic analysis webpage is attached.
              </p>
            </div>
          </div>
          <div class="itemselection">
            <h2>
              Metabolite input
            </h2>
            <div class="margin1">
              <div class="margin2">
                <h3>Search Item<Tips v-if="searchType == 'name'"
                    message="Before search locus, you need search the library using the meatbolite name, and then select no more than 3 metabolites for locus search."
                    :width="400"></Tips>
                </h3>
                <el-select v-model="searchType" class="m-2" placeholder="Select">
                  <el-option key="id" label="ID" value="id"></el-option>
                  <el-option key="name" label="Name" value="name"></el-option>
                </el-select>
              </div>

              <div class="margin2">
                <h3 v-show="searchType=='id'">PMhub ID</h3>
                <h3 v-show="searchType=='name'">Name</h3>
                <div class="speciesselection" style="margin-top: 20px">
                  <div style="width:300px">
                    <el-input v-model="metabolite" placeholder="Please input the metabolite ID" maxlength="11">
                    </el-input>
                  </div>
                </div>
              </div>
              <div class="margin2" v-show="searchType=='name'">
                <h3></h3>
                <el-button type="primary" :icon="Search" :loading="loading1" @click="searchName">Search
                  <Search style="width:12; height:12" />
                </el-button>
              </div>
            </div>
            <div v-show="metaboliteData && searchType != 'id'">


              <el-table :data="metaboliteData" style="width: 80%" @row-click="detailSearch" max-height="400"
                @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="PMhub ID" width="180">
                  <template #default="scope">
                    <p>{{ scope.row.metabolite }}</p>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="Name" width="280"></el-table-column>
                <el-table-column label="Structure">
                  <template #default="scope">
                    <div :id="scope.row.metabolite" style="width: 200px;height: 200px;"></div>
                  </template>
                </el-table-column>
                <el-table-column prop="molecular" label="Formula"></el-table-column>
                <el-table-column prop="molWt" label="Average Mass"></el-table-column>
                <el-table-column prop="exactMolWt" label="Monoisotopic Mass"></el-table-column>

                <!-- <el-table-column>
                <template #header>
                  selection
                </template>
                <template #default="scope" width="200">
                  <el-checkbox v-model="scope.row.selection" label="" size="large" />
                </template>
              </el-table-column> -->
              </el-table>
              <!-- <el-pagination background layout="sizes, prev, pager, next, jumper" :total="metaboliteData.length"
                v-model:current-page="pagesize.page" v-model:page-size="pagesize.size" :page-sizes="[10,20,50]">
              </el-pagination> -->
            </div>
          </div>
          <div class="itemselection">
            <h2>
              Filter parameters
            </h2>
            <h3>Article annotation level</h3>
            <el-select v-model="identify.articleAnno" class="m-2" placeholder="Select">
              <el-option key="standard" label="standard" value="standard"></el-option>
              <el-option key="all" label="all" value="all"></el-option>
            </el-select>
            <h3>Re-annotation threshould<Tips
                message="<b>'Re-annotation threshould'</b>: The threshold is set for the score calculated by a modified dot-product function to assess the similarity between the experimental spectrum and the standard spectrum in the library.<br><div><img src='/img/math.svg' width='400'></div>"
                :width="400">

              </Tips>
            </h3>
            <el-input-number v-model="identify.reAnno" :min="0.4" :max="1" :step="0.1" />
            <h3>Species selection</h3>
            <el-checkbox-group v-model="species" size="small" class="margin">
              <el-checkbox-button v-for="specie in speciesAll" :key="specie" :label="specie">
                {{ this.$store.state.speciesDict[specie] }}
              </el-checkbox-button>
            </el-checkbox-group>
            <div class="margin">
              <el-button type="primary" :icon="Search" :loading="loading" @click="search" :disabled="searchable">Search
                <Search style="width:12; height:12" />
              </el-button>
            </div>

          </div>
          <div class="itemselection" v-if="gwas">
            <h2>
              Results<Tips
                message="This section contains mGWAS results for all the features annotated with selected metabolites. "
                :width="400"></Tips>
            </h2>
            <div v-for="featureAnno in gwas.featuresAnno">
              <el-table :data="[featureAnno]">
                <el-table-column label="MS/MS ID" width="180">
                  <template #default="scope">
                    <a :href="$store.state.frontbaseURL+'featuredetail/'+scope.row.featureID">{{ scope.row.featureID
                      }}</a>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="featureID" width="180" label="featureID"></el-table-column> -->
                <el-table-column width="100" label="Species">
                  <template #default="scope">
                    {{ this.$store.state.speciesDict[scope.row.species]}}
                  </template>
                </el-table-column>
                <el-table-column prop="identificationLevel" width="160" label="Identification Level"></el-table-column>
                <el-table-column prop="name" width="360" label="Article annotation name"></el-table-column>
                <el-table-column prop="metabolite2" width="200" label="Re-annotation"></el-table-column>
                <el-table-column prop="alignScore" width="180" label="Re-annotation score"></el-table-column>
                <el-table-column prop="name2" label="Re-annotation name" width="360"></el-table-column>
              </el-table>
              <div class="gwas">
                <b style="margin-right: 20px;">Replications</b>
                <el-radio-group v-model="gwasRep[featureAnno.featureID]" :id="'radio_'+featureAnno.featureID"
                  fill="#409EFF" disabled>
                  <el-radio label="rep1">
                    <el-button @click="changeRep(featureAnno.featureID,'rep1')">
                      rep1
                    </el-button>
                  </el-radio>
                  <el-radio label="rep2">
                    <el-button @click="changeRep(featureAnno.featureID,'rep2')">
                      rep2
                    </el-button>
                  </el-radio>
                  <el-radio label="rep3">
                    <el-button @click="changeRep(featureAnno.featureID,'rep3')">
                      rep3
                    </el-button>
                  </el-radio>
                </el-radio-group>
                <div :id="'gwasPlot_'+featureAnno.featureID"></div>
                <hr style="border-top: thick dashed #6c88b2" />
              </div>

            </div>

          </div>
        </el-col>
      </el-row>

    </div>
  </div>
</template>
<!--  prop="metabolite" -->
<script>
  import request from '@/network/request'
  import $ from 'jquery'
  import MetaboliteBrowseCompnentSearch from "@/components/Browse/MetaboliteBrowseCompnentSearch.vue"
  import { ref } from 'vue'
  import { Edit, Upload, Picture, Search } from '@element-plus/icons-vue'
  import common from '@/mymethods/common.js'
  import { ElMessage, ElLoading } from 'element-plus'
  import Tips from '@/components/Common/Tips.vue'
  export default {
    name: 'LocSearchComponent',
    data () {
      return {
        step: 0,
        chromosomeInfo: [],
        result: [],
        chromosome: '',
        chromosomeSelected: {},
        intervel: { 'left': 0, 'right': 4999999 },
        loading: false,
        loading1: false,
        head: "Metabolite to Locus",
        // searchType: 'id',
        // metabolite: "MS000000016",
        searchType: 'name',
        metabolite: "Naringenin",
        metabolites: [],
        identify: {
          "articleAnno": "standard",
          "reAnno": 0.4
        },
        speciesAll: ["trae", "zema", "orsa", "lyes"],
        species: ["trae", "zema"],
        gwas: "",
        gwasRep: {},
        repT1: 'rep1',
        repT2: 'rep2',
        pagesize: {
          page: 1,
          size: 10,
        },
        metaboliteData: '',
        selectionAll: '',
        searchable: true,
      }
    },
    methods: {
      test () {
        this.count += 1
      },
      getChromosomeInfo (seachParams) {
        request({
          url: 'Chromosome/list/',
          params: seachParams,
        }).then(res => {

          this.chromosomeInfo = res
          this.chromosomeInfo.sort((a, b) => a.id - b.id)
        }).catch(err => {
          console.log(err);
        });
      },
      search () {
        this.step = 3
        let speciesT = this.species.join()
        if (this.searchType == "id") {
          var metaboliteS = this.metabolite
          var numberT = 1
        } else {
          var metaboliteS = this.metabolites.join()
          var numberT = this.metabolites.length
        }
        if (numberT > 3) {
          var messageT = "The number of selected metabolites cannot exceed 3."
          this.alertErr(messageT)
        } else if (numberT == 0) {
          var messageT = "You must select at least a metabolite, no more than 3."
          this.alertErr(messageT)
        }
        else {
          let seachParams = { species: speciesT, identify: this.identify, metabolite: metaboliteS }
          this.loading = true
          if (this.species.length > 0) {
            request({
              url: 'Metabolite/gwas/',
              params: seachParams,
            }).then(res => {

              this.gwas = res
              this.loading = false
              this.$nextTick(() => {
                for (let i in this.gwas.featuresAnno) {
                  // console.log("iiiiiiii")
                  // console.log(this.gwas.featuresAnno[i].featureID)
                  this.changeRep(this.gwas.featuresAnno[i].featureID, "rep1")
                }
              })
            }).catch(err => {
              console.log(err);
              this.loading = false
            });
          } else {
            console.log("err")
            this.loading = false
          }
        }
      },
      getSearchData (seachParams) {

        this.loading1 = true
        request({
          url: 'metabolite/search/',
          params: seachParams,
        }).then(res => {

          this.metaboliteData = res
          let page = this.pagesize.page
          let size = this.pagesize.size
          this.loading1 = false
        }).catch(err => {
          console.log(err);
          this.loading1 = false
        });
      },
      searchName () {
        if (this.metabolite == "") {
          var messageT = "Please input a metabolite name."
          this.alertErr(messageT)
        } else {
          var seachParams = {
            selectItem: this.searchType,
            searchItem: this.metabolite
          }
          this.getSearchData(seachParams)
          this.searchable = false
        }

        // 需要加入名称搜索网页的功能！！！！！

      },
      changeRep (item, rep) {

        this.gwasRep[item] = rep
        var gwasT = []

        for (var i = 0; i < this.gwas[item].length; i++) {
          if (this.gwas[item][i].rep == rep) {
            gwasT.push(this.gwas[item][i])
          }
        }

        var specieT = item.split("_")[0]
        common.drawGwas({
          gwasData: gwasT,
          chomosomeData: this.gwas[specieT],
          gwasID: 'gwasPlot_' + item,
          plot_width: 1600,
          plot_height: 400
        })
      },
      handleSelectionChange (val) {

        if (val.length > 3) {
          var messageT = "The number of selections must belows to 3."
          this.alertErr(messageT)
        } else {
          var metabolitesT = []
          for (let i in val) {
            metabolitesT.push(val[i].metabolite)
          }
          this.metabolites = metabolitesT
        }
      },
      alertErr (messageT) {
        ElMessage({
          message: messageT,
          type: 'error',
          showClose: true,
        })
      }
    },
    components: {
      MetaboliteBrowseCompnentSearch,
      Edit,
      Upload,
      Picture,
      Search,
      Tips,
    },
    created () {

    },
    mounted () {
      window.vue = this
      this.step = 2
    },
    updated () {

    },
    // 检测querydata的编号
    watch: {
      searchType: {
        handler (val, oldVal) {
          if (val == "id") {
            this.searchable = false
            this.metabolite = "MS000000016"
          } else {
            this.searchable = true
            this.metabolite = ""
          }
        }
      },
      metabolite: {
        handler (val, oldVal) {
          if (val.length == 11) {
            this.step = 2
            this.gwas = ""
          } else {
            this.step = 1
            this.gwas = ""
          }
        }
      },
      intervel: {
        handler (val, oldVal) {
        }
      },
      metaboliteData: {
        handler (newName, oldName) {
          this.$nextTick(() => {
            for (let item in newName) {
              common.drawMol(newName[item].mol, newName[item].metabolite)
            }
          })
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  #locsearchcomponent {
    min-height: 800px
  }

  .el-main {
    background-color: #e9eef3;
    color: #333;
    text-align: center;
  }

  .el-select .el-input {
    width: 180px;
  }

  .input-with-select .el-input-group__prepend {
    background-color: #fff;
  }

  .el-table {
    cursor: pointer;
  }


  .el-input {
    margin: 1px 0px 3px 0px;
  }

  h3 {
    height: 15px;
    margin: 30px 0px 20px 0px;
  }

  /* .el-slider {
    ::v-deep .el-slider__input {
      width: 200px;
      float: none;
    }
  } */

  .el-slider {
    :deep(.el-slider__input) {
      width: 200px;
      float: none;
    }
  }

  .margin {
    margin: 20px 5px 20px 0px
  }

  .margin1 {
    display: flex;
    align-content: space-around;
    margin-bottom: 20px;
  }

  .margin2 {
    margin-right: 40px;
  }

  /* .el-radio {
    :deep(.el-radio__inner::after) {
      background-color: red;
      border-color: red;
    }
  } */

  .el-radio {
    :deep(.el-radio__input.is-disabled.is-checked) {
      .el-radio__inner {
        background-color: #e56b07;
        border-color: #e56b07;
      }

    }
  }

  .gwas {
    margin-top: 20px;
  }

  /* .rightslider>>>.el-slider>>>.el-slider__input {
    width: 200px;
    float: none;
  } */
</style>